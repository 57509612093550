import dayjs, { Dayjs } from 'dayjs';
import {
  groupBy,
  isEqual,
  isNil,
  omit,
  partition,
  pick,
  uniqWith,
} from 'lodash';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { getTodayYesterdayAppender } from 'xo/date-utils';
import { ApiRiskAssessment } from 'xo/graphql/api/enums/risk-assessment.generated';
import {
  FormConfigQuestionExpectsModel,
  FormConfigReviewPromptModel,
  FormKind,
  RiskAssessment,
} from 'xo/rest-api';
import { IconMap } from '../../models/icons';
import {
  AnswerValueMap,
  DateUnsureAnswer,
  FormConfigModel,
  FormConfigQuestionModel,
  FormConfigQuestionMostRecentVisitModel,
  QuestionResponseMapModel,
  VisitSummaryModel,
} from '../../models/visitor-log-models';
import { MOST_RECENT_GROUP_CONFIG, isShowOnEqual } from '../e2e-shared';
import { MostRecentVisits } from '../host-review/most-recent-visits';
import { SiteEntries } from '../host-review/site-entries';
import {
  compliantToPolicy,
  notCompliantToPolicy,
} from '../people-office/visitor-log-constants';
import {
  CombinedHostReviewGroup,
  resolveHostReviewGroup,
} from '../people-office/visitor-log-hooks';

export const getQuestionResponseDisplayValue = ({
  questionId,
  value,
  expects,
  mostRecentVisits,
  mostRecentVisitBorder = true,
}: {
  questionId: string;
  value: any;
  expects: FormConfigQuestionExpectsModel;
  mostRecentVisits?: FormConfigQuestionMostRecentVisitModel[];
  mostRecentVisitBorder?: boolean;
}): string | JSX.Element => {
  if (Array.isArray(value) && expects.type !== 'SITE_ENTRIES') {
    return (
      <>
        {value.map((v, i) => (
          <React.Fragment key={v}>
            <span>{i > 0 ? ', ' : null}</span>
            {getQuestionResponseDisplayValue({
              questionId,
              value: v,
              expects,
              mostRecentVisits,
            })}
          </React.Fragment>
        ))}
      </>
    );
  }

  let displayValue = value;
  if (expects.type === 'SITE_ENTRIES') {
    displayValue = <SiteEntries entries={value} />;
  } else if (mostRecentVisits?.length) {
    displayValue = (
      <MostRecentVisits
        mostRecentVisits={mostRecentVisits}
        border={mostRecentVisitBorder}
      />
    );
  } else if (expects.type === 'BOOL' && typeof value === 'boolean') {
    displayValue = value ? 'Yes' : 'No';
  } else if (
    (expects.type === 'DATE_UNSURE' || expects.type === 'DATE') &&
    ([DateUnsureAnswer.MORE_THAN_QUARANTINE, DateUnsureAnswer.NEVER].includes(
      value,
    ) ||
      dayjs.isDayjs(value))
  ) {
    if (dayjs.isDayjs(value)) {
      displayValue = value.format('D MMM YYYY');
    } else if (DateUnsureAnswer.NEVER === value) {
      displayValue = 'Never';
    } else if (DateUnsureAnswer.MORE_THAN_QUARANTINE === value) {
      displayValue = 'Outside quarantine period';
    }
  } else if (
    expects.type === 'OPTIONS' &&
    expects.options?.some(o => o.value === value)
  ) {
    const option = expects.options.find(o => o.value === value)!;
    displayValue = (
      <ReactMarkdown components={{ p: props => <>{props.children}</> }}>
        {option.displayLabel ?? option.label}
      </ReactMarkdown>
    );
  } else if (expects.type === 'STRING') {
    displayValue = value;
  } else if (value === undefined || value === null) {
    displayValue = 'No answer';
  }

  return displayValue;
};

export interface HostReviewQuestion {
  questionId: string;
  shortText: string;
  text?: string;
  displayValue: string | JSX.Element;
  value: any;
  risk: RiskAssessment;
  riskFromReview: boolean;
  reviewFromVisit?: VisitSummaryModel;
  expects: FormConfigQuestionExpectsModel;
  mostRecentVisits?: FormConfigQuestionMostRecentVisitModel[];
  pageTitle: string;
  preceeders?: HostReviewQuestionPreceeder[];
}

export interface HostReviewQuestionPreceeder {
  icon: keyof typeof IconMap;
  text: string;
  e2eHide?: boolean;
}

export interface HostReviewGroup {
  reviewPrompt: FormConfigReviewPromptModel;
  questions: HostReviewQuestion[];
  hostResponse?: HostReviewGroupResponse;
  reviewFromVisit?: VisitSummaryModel;
}

export interface HostReviewGroupResponse {
  risk: RiskAssessment;
  agreement?: boolean;
  comment?: string;
  reviewedRisk?: RiskAssessment;
}

// Processes the questionnaire responses into groups based on their review prompts
// and dependsOn settings. It also include config useful for rendering
// during host review, risk assessment and assessment summary
export const compileQuestionnaireResponses = ({
  config,
  responses,
  mostRecentVisitBorder = true,
}: {
  config?: FormConfigModel;
  responses: QuestionResponseMapModel;
  mostRecentVisitBorder?: boolean;
}): HostReviewGroup[] => {
  if (!config) return [];

  const questionIds = new Set(Object.keys(responses));
  const filteredPages = config.pages.map(p => ({
    ...p,
    questions: p.questions.filter(q => questionIds.has(q.questionId)),
  }));

  const questionIdToQuestion = new Map(
    config.pages.flatMap(p => p.questions.map(q => [q.questionId, q])),
  );

  const allDependsOn = (questionId: string) => {
    let question = questionIdToQuestion.get(questionId);
    const ids = [];
    while (question?.dependsOn) {
      const parent = question.dependsOn.questionId;
      ids.push(parent);
      question = questionIdToQuestion.get(parent);
    }
    return ids;
  };

  const compiledResponses = filteredPages.flatMap(p =>
    p.questions
      .filter(
        q =>
          // Doesn't have dependent questions OR does have a dependency and values match
          !q.dependsOn ||
          isShowOnEqual({
            value: responses[q.dependsOn.questionId]?.value,
            showOn: q.dependsOn.showOn,
          }),
      )
      .map(
        ({
          questionId,
          expects,
          text,
          shortText,
          reviewPrompt,
          dependsOn,
          mostRecentVisits,
        }) => ({
          questionId,
          shortText: mostRecentVisits.length === 0 ? shortText : '',
          text,
          displayValue: getQuestionResponseDisplayValue({
            questionId,
            value: responses[questionId].value,
            expects,
            mostRecentVisits,
            mostRecentVisitBorder,
          }),
          dependsOn,
          reviewPrompt: getReviewPromptByValue(
            reviewPrompt!,
            responses[questionId].value,
          ),
          expects,
          mostRecentVisits,
          pageTitle: p.title,
          ...responses[questionId],
        }),
      ),
  );

  const isPromptEqual = (
    rp1?: FormConfigReviewPromptModel,
    rp2?: FormConfigReviewPromptModel,
  ) => isEqual(omit(rp1, ['onValues']), omit(rp2, ['onValues']));

  // Group responses by review prompt or parent review prompt and maintain order
  const groupedResponses: HostReviewGroup[] = uniqWith(
    compiledResponses.map(r => r.reviewPrompt),
    isPromptEqual,
  ).map(reviewPrompt => {
    const reviewPromptResponses = compiledResponses.filter(r =>
      isPromptEqual(r.reviewPrompt, reviewPrompt),
    );

    const dependsOnIds = new Set(
      reviewPromptResponses.flatMap(r => allDependsOn(r.questionId)),
    );

    const reviewPromptResponseIds = new Set(
      reviewPromptResponses.map(r => r.questionId),
    );

    const dependsOnResponses = compiledResponses.filter(
      r =>
        // Include parent questions
        (dependsOnIds.has(r.questionId) ||
          // Include any questions that share a dependent question
          (r.dependsOn && dependsOnIds.has(r.dependsOn.questionId))) &&
        // Don't duplicate questions with this rule
        !reviewPromptResponseIds.has(r.questionId) &&
        !r.reviewPrompt,
    );

    return {
      reviewPrompt,
      questions: dependsOnResponses
        .concat(reviewPromptResponses)
        .map(r => omit(r, ['reviewPrompt', 'dependsOn'])),
    };
  });

  // Remove any questions from the empty reviewPrompt group to ensure they don't duplicate
  // eg. When "drive" is "No" it should remain
  // When "drive" is "Yes" and appears grouped with other responses, it shouldn't be in the empty group
  const emptyReviewPromptGroupIndex = groupedResponses.findIndex(
    r => !r.reviewPrompt,
  );
  if (emptyReviewPromptGroupIndex > -1) {
    const emptyReviewPromptGroup =
      groupedResponses[emptyReviewPromptGroupIndex];
    const nonEmptyGroupQuestionIds = new Set(
      groupedResponses
        .filter(r => r.reviewPrompt)
        .flatMap(r => r.questions.map(q => q.questionId)),
    );
    emptyReviewPromptGroup.questions = emptyReviewPromptGroup.questions.filter(
      q => !nonEmptyGroupQuestionIds.has(q.questionId),
    );

    // Assign titles for questions with no review prompt and create new groups if necessary
    const extraGroups: HostReviewGroup[] = Object.entries(
      groupBy(emptyReviewPromptGroup.questions, q => q.pageTitle),
    ).map(([title, questions]) => ({
      reviewPrompt: { title, question: '', options: [] },
      questions,
    }));
    groupedResponses.splice(emptyReviewPromptGroupIndex, 1, ...extraGroups);
  }

  // Ensure no questions are duplicated
  const groupQuestionIds: string[] = [];
  for (const group of groupedResponses) {
    group.questions = group.questions.filter(
      q => !groupQuestionIds.includes(q.questionId),
    );

    groupQuestionIds.push(...group.questions.map(q => q.questionId));
  }

  const createRecentVisitPreceeders = (
    siteName: string,
    date: Dayjs,
  ): HostReviewQuestionPreceeder[] => {
    const extraText = getTodayYesterdayAppender(date);

    return [
      {
        icon: 'location',
        text: siteName,
      },
      {
        icon: 'calendar-today',
        text: `${date.format('D MMM YYYY')}${extraText}`,
        e2eHide: true,
      },
    ];
  };

  // Format answers for recent visits outside ExoFlare with preceeder items
  // and combine into single
  MOST_RECENT_GROUP_CONFIG.forEach(
    ({
      parentQuestionId,
      orgQuestionId,
      dateQuestionId,
      siteQuestionId,
      internal,
      siteIndicatesCompliance,
    }) => {
      const group = groupedResponses.find(g =>
        g.questions.some(q => q.questionId === parentQuestionId),
      );

      if (group) {
        const getQuestion = (
          questionId: string,
        ): [number, HostReviewQuestion] => {
          const index = group.questions.findIndex(
            q => q.questionId === questionId,
          );
          return [index, group.questions[index]];
        };

        const [, parentQuestion] = getQuestion(parentQuestionId);

        if (parentQuestion) {
          const [orgIndex, orgQuestion] = getQuestion(orgQuestionId);
          const [dateIndex, dateQuestion] = getQuestion(dateQuestionId);
          const [siteIndex, siteQuestion] = getQuestion(siteQuestionId);

          if (dateQuestion && siteQuestion) {
            parentQuestion.preceeders = createRecentVisitPreceeders(
              siteQuestion.displayValue as string,
              dateQuestion.value,
            );

            parentQuestion.expects.icon = undefined;
            parentQuestion.risk = getMaxRisk([
              parentQuestion.risk,
              dateQuestion.risk,
              siteQuestion.risk,
              orgQuestion.risk,
            ])!;
            parentQuestion.shortText =
              (siteIndicatesCompliance ? siteQuestion : dateQuestion).risk ===
              'LOW'
                ? compliantToPolicy({ internal })
                : notCompliantToPolicy({ internal });
            parentQuestion.displayValue = '';

            group.questions = group.questions.filter(
              (q, i) =>
                ![siteIndex, dateIndex, orgIndex].includes(i) ||
                // make sure not to remove the parent question, if it's also one of the others
                q.questionId === parentQuestionId,
            );
          }
        }
      }
    },
  );

  return groupedResponses.filter(r => r.questions.length > 0);
};

// partition the groups into the non-low groups and the low groups
const partitionReviewGroups = (groups: HostReviewGroup[]) =>
  partition(groups, g =>
    g.questions.some(q => q.risk !== ApiRiskAssessment.Low),
  );
export const getHighRiskReviewGroups = (groups: HostReviewGroup[]) =>
  partitionReviewGroups(groups)[0];
export const getLowRiskReviewGroups = (groups: HostReviewGroup[]) =>
  partitionReviewGroups(groups)[1];

export const getReviewPromptByValue = (
  reviewPrompt: FormConfigReviewPromptModel | FormConfigReviewPromptModel[],
  value: any,
) =>
  Array.isArray(reviewPrompt)
    ? reviewPrompt.find(
        r =>
          !r.onValues ||
          r.onValues.includes(value) ||
          (Array.isArray(value) && r.onValues.some(v => value.includes(v))),
      )!
    : reviewPrompt;

export const compileHostResponses = ({
  config,
  hostResponses,
}: {
  config: FormConfigModel;
  hostResponses: QuestionResponseMapModel;
}) => {
  const hostResponseMap = new Map<string, HostReviewGroupResponse>();

  const questionIds = config.pages.flatMap(p =>
    p.questions.map(q => q.questionId),
  );

  questionIds.forEach(questionId => {
    const key = (item: string) => `${questionId}-host-${item}`;

    const response: HostReviewGroupResponse = {
      agreement: hostResponses[key('agreement')]?.value,
      risk: hostResponses[key('agreement')]?.risk,
      comment: hostResponses[key('comment')]?.value?.trim(),
      reviewedRisk: hostResponses[key('reviewed-risk')]?.value,
    };

    // Only assign host response if it contains something meaningful
    if (Object.values(response).some(value => !isNil(value))) {
      hostResponseMap.set(questionId, response);
    }
  });

  return hostResponseMap;
};

export const applyHostResponses = ({
  config,
  groups,
  hostResponses,
}: {
  config: FormConfigModel;
  groups: HostReviewGroup[];
  hostResponses: QuestionResponseMapModel;
}) => {
  const hostResponseMap = compileHostResponses({
    config,
    hostResponses,
  });

  const newGroups = groups.map(group => {
    // Use the first high risk question or fall back to answer valid question since they've been set as a group
    // and should share the same values
    const canonicalQuestion =
      group.questions.find(q => q.reviewFromVisit) ??
      group.questions.find(q => q.risk && q.risk !== 'LOW') ??
      group.questions.find(q => hostResponseMap.has(q.questionId));
    if (!canonicalQuestion) {
      return group;
    }

    const hostResponse = hostResponseMap.get(canonicalQuestion.questionId);

    return {
      ...group,
      hostResponse,
      reviewFromVisit: canonicalQuestion.reviewFromVisit,
    };
  });

  return newGroups;
};

export const getMaxRisk = (risks: RiskAssessment[]): RiskAssessment => {
  const riskRanks: RiskAssessment[] = ['HIGH', 'MEDIUM', 'LOW'];
  return riskRanks.find(risk => risks.includes(risk))!;
};

export const getDependentQuestions = ({
  allQuestions,
  questionId,
}: {
  allQuestions: FormConfigQuestionModel[];
  questionId: string;
}) =>
  allQuestions.filter(
    q => q.dependsOn && questionId === q.dependsOn.questionId,
  );

export const getAllDependentQuestions = ({
  allQuestions,
  questionId,
}: {
  allQuestions: FormConfigQuestionModel[];
  questionId: string;
}): FormConfigQuestionModel[] => {
  const children = getDependentQuestions({ allQuestions, questionId });
  return children.concat(
    children.flatMap(q =>
      getAllDependentQuestions({ allQuestions, questionId: q.questionId }),
    ),
  );
};

export const getAllHiddenDependentQuestions = ({
  config,
  questionIds,
  answers,
  parentHidden,
}: {
  config: FormConfigModel;
  questionIds: string[];
  answers: { [questionId: string]: any };
  parentHidden?: boolean;
}): FormConfigQuestionModel[] => {
  const allQuestions = config.pages.flatMap(p => p.questions);
  const children = questionIds.flatMap(questionId =>
    getDependentQuestions({
      allQuestions,
      questionId,
    }),
  );

  const hiddenChildren = children.filter(
    c =>
      parentHidden ||
      !isShowOnEqual({
        value: answers[c.dependsOn!.questionId],
        showOn: c.dependsOn!.showOn,
      }),
  );

  if (hiddenChildren.length === 0) return hiddenChildren;

  return hiddenChildren.concat(
    getAllHiddenDependentQuestions({
      config,
      questionIds: hiddenChildren.map(c => c.questionId),
      answers,
      parentHidden: true,
    }),
  );
};

// FIXME Move Most recent visit grouping config to backend
export const mostRecentVisitQuestionIds = new Set(
  MOST_RECENT_GROUP_CONFIG.flatMap(c => [
    c.dateQuestionId,
    c.orgQuestionId,
    c.parentQuestionId,
    c.siteQuestionId,
  ]),
);

const isLowOnsiteHigherEarly = (
  reviewKind: FormKind,
  combinedReviewGroup: CombinedHostReviewGroup,
  onsiteQuestion: HostReviewQuestion,
) => {
  const earlyQuestion = combinedReviewGroup.early?.questions.find(
    eq =>
      eq.questionId === onsiteQuestion.questionId ||
      (mostRecentVisitQuestionIds.has(eq.questionId) &&
        mostRecentVisitQuestionIds.has(onsiteQuestion.questionId)),
  );

  return (
    reviewKind === 'ONSITE_HOST' &&
    onsiteQuestion.risk === 'LOW' &&
    earlyQuestion &&
    // Check if the early question was low, or potentially it was another question in the group that wasn't
    (earlyQuestion?.risk !== 'LOW' ||
      combinedReviewGroup.early?.questions.some(q => q.risk !== 'LOW'))
  );
};

// Review non-low risk questions in the group, or those that were non-low in early
// but high in onsite
export const getReviewGroupAndReviewQuestionIds = (
  reviewKind: FormKind,
  combinedReviewGroup: CombinedHostReviewGroup,
) => {
  const reviewGroup = resolveHostReviewGroup(reviewKind, combinedReviewGroup);

  const reviewGroupQuestions =
    reviewGroup?.questions.filter(
      q =>
        q.risk !== 'LOW' ||
        // Check if the question was LOW in the onsite but HIGH in the early
        // and include for review if so
        isLowOnsiteHigherEarly(reviewKind, combinedReviewGroup, q),
    ) ?? [];

  return {
    reviewGroup,
    reviewQuestions:
      reviewGroupQuestions.map(q => ({
        risk: q.risk,
        questionId: q.questionId,
      })) ?? [],
    hasLowOnsiteHigherEarly: reviewGroupQuestions.some(q =>
      isLowOnsiteHigherEarly(reviewKind, combinedReviewGroup, q),
    ),
  };
};

export const getReviewQuestions = (
  reviewKind: FormKind,
  combinedReviewGroups: CombinedHostReviewGroup[],
) =>
  combinedReviewGroups.flatMap(
    combinedReviewGroup =>
      getReviewGroupAndReviewQuestionIds(reviewKind, combinedReviewGroup)
        .reviewQuestions,
  );

export const isQuestionVisible = ({
  questionId,
  questionMap,
  answers,
}: {
  questionId: string;
  answers: AnswerValueMap;
  questionMap: Map<string, FormConfigQuestionModel>;
}) => {
  const isVisible = (questionId: string): boolean => {
    const question = questionMap.get(questionId)!;
    if (question.dependsOn) {
      const visible = isShowOnEqual({
        value: answers[question.dependsOn.questionId],
        showOn: question.dependsOn.showOn,
      });

      if (!visible) return false;

      return isVisible(question.dependsOn.questionId);
    }

    return true;
  };

  return isVisible(questionId);
};
// Only use select fields for equality since text and risk can change between early and onsite
const getEqualityQuestions = (group: HostReviewGroup) =>
  group.questions.map(q => pick(q, 'questionId', 'value'));

export const groupAnswersChanged = (
  a?: HostReviewGroup,
  b?: HostReviewGroup,
) =>
  a && b
    ? !isEqual(getEqualityQuestions(a), getEqualityQuestions(b))
    : undefined;

export const getEmptyRequiredVisibleQuestions = ({
  questions,
  answers,
}: {
  questions: FormConfigQuestionModel[];
  answers: AnswerValueMap;
}) => {
  const questionMap = new Map(questions.map(q => [q.questionId, q]));

  const visibleRequiredQuestions = questions.filter(
    ({ questionId, expects }) =>
      expects.required &&
      isQuestionVisible({ questionId, questionMap, answers }),
  );
  const emptyRequiredVisibleQuestions = visibleRequiredQuestions.filter(q =>
    isResponseEmpty(answers[q.questionId]),
  );

  return emptyRequiredVisibleQuestions;
};

export const isResponseEmpty = (value: any) =>
  isNil(value) || value === '' || (Array.isArray(value) && value.length === 0);

export const isQuestionIncomplete = ({
  question: { expects },
  value,
}: {
  question: Pick<FormConfigQuestionModel, 'expects'>;
  value: any;
}) => isResponseEmpty(value) && expects.type !== 'NO_RESPONSE';
