import { useViewportSize } from '@mantine/hooks';
import { Drawer, Tooltip } from 'antd';
import React from 'react';
import { Switch } from 'react-router-dom';
import { appRoutes, transportRoutes } from 'xo/navigation/web-routes';
import { useInterfaceSettingsStore } from 'xo/store/interface-settings-store';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCross } from 'xo/svg/svg-cross';
import { useFlag } from '../../hooks/shared-hooks';
import { Button } from '../components/button';
import { LoggedInRoute } from '../components/logged-in-route';
import { TooltipButton } from '../components/tooltip-button';
import { FormItem } from '../forms/form';
import { LocalStorage } from '../shared/local-storage';
import { AdminAssumeUser } from './admin-assume-user';
import { AdminDelivery } from './admin-delivery';
import { AdminTransportTripDetails } from './admin-transport-trip-details';
import { AdminVisit } from './admin-visit';

export const AdminDrawer: React.FC = () => {
  const xoUIHidden = useInterfaceSettingsStore(state => state.xoUIHidden);
  const [visible, onSetVisible, onUnsetVisible] = useFlag(false);

  const { width: screenWidth } = useViewportSize();
  const width = screenWidth > 1100 ? '1100px' : '90vw';

  if (xoUIHidden) return null;

  return (
    <>
      <Tooltip overlay={<AdminAssumeUser />} trigger={['hover']}>
        <TooltipButton className="bg-red-800" onClick={onSetVisible}>
          XO
        </TooltipButton>
      </Tooltip>
      <Drawer
        placement="right"
        width={width}
        closable={false}
        onClose={onUnsetVisible}
        visible={visible}
        bodyStyle={{ padding: 0, backgroundColor: colors.white }}
      >
        <div className="flex h-12 items-center bg-red-800 px-4">
          <button onClick={onUnsetVisible}>
            <SvgCross fill={colors.white} />
          </button>
          <div className="ml-4 text-lg font-semibold text-white">Admin</div>
        </div>
        <div className="p-4">
          <div className="ant-form ant-form-vertical">
            <LoggedInRoute>
              <FormItem label="Assume user">
                <AdminAssumeUser clearLink={true} />
              </FormItem>
            </LoggedInRoute>

            <FormItem
              label="Clear local storage"
              tooltip="This clears local storage for the browser except for authentication details (ie. you'll remain logged in). Removes things such as the saved visitor for check-in and questionnaire progress. Useful for testing. You might need to refresh to see the change take effect."
            >
              <Button onClick={LocalStorage.clearAllExceptAuth}>Clear</Button>
            </FormItem>

            <Switch>
              <LoggedInRoute path={appRoutes.visits.details()}>
                <AdminVisit />
              </LoggedInRoute>
              <LoggedInRoute path={appRoutes.deliveries.details()}>
                <AdminDelivery />
              </LoggedInRoute>
              <LoggedInRoute path={transportRoutes.tripDetails()}>
                <AdminTransportTripDetails />
              </LoggedInRoute>
            </Switch>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default AdminDrawer;
