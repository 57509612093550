import dayjs, { Dayjs } from 'dayjs';
import { kebabCase, omit, sortBy } from 'lodash';
import {
  combineDateAndTime,
  formatLocalDateTime,
  parseDate,
  parseTime,
} from 'xo/date-utils';
import { ApiInternalVisitorStatus } from 'xo/graphql/api/enums/internal-visitor-status.generated';
import { ApiUserKind } from 'xo/graphql/api/enums/user-kind.generated';
import { ApiUserStatus } from 'xo/graphql/api/enums/user-status.generated';
import { ApiOrganisationFragment } from 'xo/graphql/api/organisation-fragment.generated';
import {
  FormConfigModel as ApiFormConfigModel,
  FormConfigPageModel as ApiFormConfigPageModel,
  FormConfigQuestionMostRecentVisitExternalModel as ApiFormConfigQuestionMostRecentVisitExternalModel,
  FormSummaryModel as ApiFormSummaryModel,
  VisitSeriesReadModel as ApiVisitSeriesReadModel,
  VisitSeriesSummaryModel as ApiVisitSeriesSummaryModel,
  VisitSummaryModel as ApiVisitSummaryModel,
  CovidVaccinationCertificateSightingReadModel,
  DeliveryPersonReadModel,
  DeliveryReadModel,
  DetailedSiteReadModel,
  DetailedUserReadModel,
  FormConfigQuestionExpectsModel,
  FormFinishRequest,
  FormInfoResponse,
  FormQuestionAnswer,
  FormReadModel,
  SiteWriteModel,
  UserReadModel,
  UserSummaryModel,
  UserVisitorReadModel,
  UserVisitorSummaryModel,
  VisitHistoryModel,
  VisitListModel,
  VisitReadModel,
  VisitSeriesWriteModel,
  VisitWriteModel,
} from 'xo/rest-api';
import { OptionType } from '../app/components/component-models';
import { getDependentQuestions } from '../app/components/question-response-helpers';
import { FormSummaryMap } from '../app/visits/visit-status-helpers';
import {
  combinePurpose,
  internalVisitorStatusAtOrg,
  internalVisitorStatuses,
} from '../app/visits/visit-utils';
import {
  AnswerValueMap,
  CovidVaccinationCertificateSightingModel,
  DeliveryModel,
  DeliveryPersonModel,
  FormConfigModel,
  FormConfigPageModel,
  FormConfigQuestionMostRecentVisitExternalModel,
  FormResponse,
  FormSummaryModel,
  NotificationType,
  QuestionResponseMapModel,
  QuestionnaireBlockedReason,
  UserModel,
  UserVisitorModel,
  VisitHistoryItemModel,
  VisitModel,
  VisitSeriesReadModel,
  VisitSeriesSummaryModel,
  VisitSummaryModel,
} from '../models/visitor-log-models';
import {
  datetimeTransformer,
  visitorTransformer,
} from './network-transformers';
import { PostQuestionnaireVariables } from './visitor-questionnaire-network-hooks';

export const blockedReasonTransformer = (
  form: FormReadModel,
  visit?: VisitModel,
): QuestionnaireBlockedReason | undefined =>
  visit?.status === 'CANCELLED' || visit?.status === 'DELETED'
    ? 'CANCELLED'
    : form.denySubmitReason
      ? form.denySubmitReason
      : undefined;

const readFormConfigQuestionMostRecentVisitExternalTransformer = (
  mrv: ApiFormConfigQuestionMostRecentVisitExternalModel,
): FormConfigQuestionMostRecentVisitExternalModel => ({
  ...mrv,
  forms: readVisitFormsTransformer(mrv.forms),
  history: readVisitHistoryTransformer(mrv.history),
  entryDateTime: dayjs(mrv.startDatetime),
  exitDateTime: dayjs(mrv.endDatetime),
});

const readFormConfigPageTransformer = (
  p: ApiFormConfigPageModel,
  pageIndex: number,
): FormConfigPageModel => ({
  ...p,
  path: kebabCase(p.title),
  questions: p.questions.map((q, questionIndex) => ({
    ...q,
    order: pageIndex * 1e6 + questionIndex,
    mostRecentVisits: (q.mostRecentVisits ?? []).map(mrv => ({
      ...mrv,
      internalVisit:
        mrv.internalVisit && readVisitTransformer(mrv.internalVisit),
      internalDelivery:
        mrv.internalDelivery && readDeliveryTransformer(mrv.internalDelivery),
      externalVisit:
        mrv.externalVisit &&
        readFormConfigQuestionMostRecentVisitExternalTransformer(
          mrv.externalVisit,
        ),
    })),
    dependentQuestions: [],
  })),
});

export const readFormConfigTransformer = ({
  config,
}: {
  config: ApiFormConfigModel;
  appendPages?: FormConfigPageModel[];
}): FormConfigModel => {
  const newConfig = {
    ...config,
    pages: config.pages.map(readFormConfigPageTransformer),
  };

  const allQuestions = newConfig.pages.flatMap(p => p.questions);
  return {
    ...newConfig,
    pages: newConfig.pages.map(p => ({
      ...p,
      questions: p.questions.map(q => ({
        ...q,
        dependentQuestions: getDependentQuestions({
          allQuestions,
          questionId: q.questionId,
        }),
      })),
    })),
  };
};

export const readVisitFormsTransformer = (
  forms: ApiFormSummaryModel[],
): FormSummaryModel[] =>
  forms.map(f => ({
    ...f,
    completedAt: f.completedAt ? dayjs(f.completedAt) : undefined,
  }));

export const readVisitHistoryTransformer = (
  history: VisitHistoryModel[],
): VisitHistoryItemModel[] =>
  history.map(i => ({
    status: i.toState,
    date: dayjs(i.transitionDatetime),
    reason: i.reason,
    qrTimestamp: i.qrTimestamp ? dayjs(i.qrTimestamp) : undefined,
  }));

export const readVisitTransformer = (v: VisitReadModel): VisitModel => {
  return {
    ...readListedVisitTransformer(v),
    borrowedAssessmentVisit: v.borrowedAssessmentVisit
      ? readVisitSummaryTransformer(v.borrowedAssessmentVisit)
      : undefined,
    visitor: readUserVisitorTransformer(v.visitor, v.site.organisation.id),
    host: readUserTransformer(v.host, v.site.organisation.id),
    forms: readVisitFormsTransformer(v.forms),
    visitSeries: v.visitSeries
      ? readVisitSeriesSummaryTransformer(v.visitSeries)
      : undefined,
  };
};

export const readVisitSeriesSummaryTransformer = (
  s: ApiVisitSeriesSummaryModel,
): VisitSeriesSummaryModel => ({
  ...s,
  startDate: parseDate(s.startDate),
  endDate: parseDate(s.endDate),
});

export const readVisitSeriesTransformer = (
  s: ApiVisitSeriesReadModel,
): VisitSeriesReadModel => ({
  ...s,
  ...readVisitSeriesSummaryTransformer(s),
  host: readUserTransformer(s.host, s.site.organisation.id),
  visitor: readUserVisitorTransformer(s.visitor, s.site.organisation.id),
  visits: s.visits.map(readListedVisitTransformer),
});

export const readListedVisitTransformer = (v: VisitListModel): VisitModel => {
  const history: VisitHistoryItemModel[] = readVisitHistoryTransformer(
    v.history,
  );

  return {
    ...omit(v, 'startDatetime', 'endDatetime', 'history'),
    createdAt: v.createdAt ? dayjs(v.createdAt) : undefined,
    entryDateTime: dayjs(v.actualStartDatetime ?? v.startDatetime),
    exitDateTime: dayjs(v.actualEndDatetime ?? v.endDatetime),
    status: v.state,
    history,
    visitor: readUserVisitorTransformer(v.visitor, v.site.organisation.id),
    host: readUserTransformer(v.host, v.site.organisation.id),
    forms: readVisitFormsTransformer(v.forms),
    actualStartDatetime: v.actualStartDatetime
      ? dayjs(v.actualStartDatetime)
      : undefined,
    actualEndDatetime: v.actualEndDatetime
      ? dayjs(v.actualEndDatetime)
      : undefined,
    scheduledStartDatetime: dayjs(v.startDatetime),
    scheduledEndDatetime: dayjs(v.endDatetime),
    visitSeries: v.visitSeries
      ? readVisitSeriesSummaryTransformer(v.visitSeries)
      : undefined,
    borrowedAssessmentVisit: v.borrowedAssessmentVisit
      ? readVisitSummaryTransformer(v.borrowedAssessmentVisit)
      : undefined,
  };
};

export const readDeliveryPersonTransformer = (
  p: DeliveryPersonReadModel,
): DeliveryPersonModel => ({
  ...p,
  covidVaccinationCertificateSighting: readCovidVaccinationCertificateSighting(
    p.covidVaccinationCertificateSighting,
  ),
});

export const readDeliveryTransformer = (
  d: DeliveryReadModel,
): DeliveryModel => ({
  ...omit(d, 'startDatetime', 'createdAt'),
  entryDateTime: dayjs(d.startDatetime),
  createdAt: d.createdAt ? dayjs(d.createdAt) : undefined,
  deliveryPerson: readDeliveryPersonTransformer(d.deliveryPerson),
  form: d.form ? readFormModelTransformer(d.form) : undefined,
});

export const readCovidVaccinationCertificateSighting = (
  covidVaccinationCertificateSighting?: CovidVaccinationCertificateSightingReadModel,
): CovidVaccinationCertificateSightingModel | undefined =>
  covidVaccinationCertificateSighting
    ? {
        sightedBy: readUserTransformer(
          covidVaccinationCertificateSighting.sightedBy,
          undefined,
        ),
        sightedDate: dayjs(covidVaccinationCertificateSighting.sightedDate),
      }
    : undefined;

export const readUserTransformer = (
  u: DetailedUserReadModel | UserReadModel | UserSummaryModel,
  organisationId: string | undefined,
): UserModel => {
  // User is a loginable user or a visitor
  return 'notifyWithEmail' in u
    ? {
        ...omit(u, 'notifyWithEmail', 'notifyWithSms', 'notifyWithDevicePush'),
        kind: u.kind as ApiUserKind,
        internalVisitorStatusAtOrganisation:
          u.internalVisitorStatusAtOrganisation as
            | ApiInternalVisitorStatus
            | undefined,
        internalVisitorStatusAtVisitOrg: organisationId
          ? internalVisitorStatusAtOrg({
              visitor: u as Pick<
                UserModel,
                | 'internalVisitorStatusAtOrganisation'
                | 'internalVisitorStatusOrganisationId'
              >,
              organisationId,
            })
          : undefined,
        notifications: (u?.notifyWithEmail ? ['email' as NotificationType] : [])
          .concat(u?.notifyWithSms ? ['sms'] : [])
          .concat(u?.notifyWithDevicePush ? ['devicePush'] : [])
          .concat(u?.notifyWithWhatsapp ? ['whatsapp'] : []),
        quietTimeStart: u.quietTimeStart
          ? parseTime(u.quietTimeStart)
          : undefined,
        quietTimeEnd: u.quietTimeEnd ? parseTime(u.quietTimeEnd) : undefined,
        createdAt: u.createdAt ? dayjs(u.createdAt) : undefined,
        organisation: u.organisation as ApiOrganisationFragment,
        features: u.features,
        roles: 'roles' in u ? u.roles : [],
        onboardedAtDate: 'onboardedAtDate' in u ? u.onboardedAtDate : undefined,
        kioskUserAtSite: 'kioskUserAtSite' in u ? u.kioskUserAtSite : undefined,
      }
    : {
        ...omit(u, 'notifyWithEmail', 'notifyWithSms', 'notifyWithDevicePush'),
        notifications: [],
        kind: ApiUserKind.Normal,
        agreedTerms: true,
        hostAtSites: [],
        transportAtSites: [],
        primaryContactAtSites: [],
        vaccineStatuses: {},
        features: [],
        // FIXME update when roles are available in REST API
        roles: [],
        onboardedAtDate: undefined,
        kioskUserAtSite: undefined,
        status: ApiUserStatus.Active,
      };
};

export const readUserVisitorTransformer = (
  u: UserVisitorReadModel | UserVisitorSummaryModel,
  organisationId: string,
): UserVisitorModel => {
  return {
    ...readUserTransformer(u, organisationId),
    covidVaccinationCertificateSighting:
      readCovidVaccinationCertificateSighting(
        u.covidVaccinationCertificateSighting,
      ),
  };
};

export const readUsersTransformer = (
  users: UserReadModel[],
  organisationId: string | undefined,
): UserModel[] =>
  sortBy(
    users.map(u => readUserTransformer(u, organisationId)),
    user => user.name.toLowerCase(),
    // disambiguators, to have a consistent order if there's people with identical names
    user => user.email?.toLowerCase(),
    user => user.phone,
  );

export const modelsToOptions = <T extends { id: string; name: string }>(
  models?: T[],
  valueFieldName?: keyof T,
): OptionType[] =>
  sortBy(
    models?.map(m => ({ label: m.name, value: m[valueFieldName ?? 'id'] })) ??
      ([] as OptionType[]),
    m => m.label,
  );

const readAnswerValue = (answer: FormQuestionAnswer) => {
  const valueKey = Object.keys(answer)
    .filter(key => key.startsWith('value'))
    .find(key => ![undefined, null].includes((answer as any)[key]));
  if (!valueKey) return null;
  const value = (answer as any)[valueKey];
  return valueKey.startsWith('valueDate') ? dayjs(value) : value;
};

const readVisitSummaryTransformer = (
  v: ApiVisitSummaryModel,
): VisitSummaryModel => ({
  ...omit(
    v,
    'createdAt',
    'actualStartDatetime',
    'actualEndDatetime',
    'history',
  ),
  createdAt: v.createdAt ? dayjs(v.createdAt) : undefined,
  entryDateTime: dayjs(v.actualStartDatetime ?? v.startDatetime),
  exitDateTime: dayjs(v.actualEndDatetime ?? v.endDatetime),
  history: readVisitHistoryTransformer(v.history),
});

export const readFormResponsesTransformer = (
  form: FormReadModel,
): QuestionResponseMapModel =>
  Object.entries(form.questions).reduce(
    (acc, [questionId, answer]: [string, FormQuestionAnswer]) => ({
      ...acc,
      [questionId]: {
        risk: answer.riskAssessment,
        value: readAnswerValue(answer),
        riskFromReview: answer.riskFromReview ?? false,
        reviewFromVisit: answer.reviewFromVisit
          ? readVisitSummaryTransformer(answer.reviewFromVisit)
          : undefined,
        provenance: answer.provenance
          ? {
              answeredAt: dayjs(answer.provenance.answeredAt),
              expiresAt: answer.provenance.expiresAt
                ? dayjs(answer.provenance.expiresAt)
                : undefined,
            }
          : undefined,
      },
    }),
    {},
  ) as QuestionResponseMapModel;

export const formSummariesToMap = (
  forms: Omit<FormSummaryModel, 'id' | 'token'>[],
): FormSummaryMap => new Map(forms.map(f => [f.kind, f])) as FormSummaryMap;

export const readFormModelTransformer = (
  form: FormReadModel,
  visit?: VisitModel,
): FormResponse => ({
  id: form.id,
  config:
    form.config &&
    readFormConfigTransformer({
      config: form.config!,
    }),
  responses: readFormResponsesTransformer(form),
  riskAssessment: form.riskAssessment,
  kind: form.kind,
  blockedReason: blockedReasonTransformer(form, visit),
  completedAt: form.completedAt ? dayjs(form.completedAt) : undefined,
  completedOnDeviceOf: form.completedOnDeviceOf,
});

export const readFormInfoResponseTransformer = (
  response: FormInfoResponse,
): FormResponse => ({
  ...readFormModelTransformer(
    response.form,
    readVisitTransformer(response.visit),
  ),
  blockedReason: blockedReasonTransformer(
    response.form,
    readVisitTransformer(response.visit),
  ),
});

export const writeSiteTransformer = (
  site: DetailedSiteReadModel,
): SiteWriteModel => ({
  name: site.name,
  timezone: site.timezone,
  kind: site.kind,
  organisation: { id: site.organisation.id },
  primaryContact: site.primaryContact?.id
    ? { id: site.primaryContact.id }
    : undefined,
  isTest: site.isTest ?? false,
  features: site.features ?? [],
  commodities: site.commodities,
  commoditiesOther: site.commoditiesOther,
  hosts: site.hosts?.map(h => ({ id: h.id })),
  defaultEntryMessage: site.defaultEntryMessage,
  useDefaultEntryMessage: site.useDefaultEntryMessage,
  requiredActions: site.requiredActions,
  suburb: site.suburb,
  state: site.state,
  postcode: site.postcode,
  countryCode: site.countryCode,
  addressLine1: site.addressLine1,
  addressLine2: site.addressLine2,
  position: site.position,
  propertyIdentificationCode: site.propertyIdentificationCode,
  questionnaireFamilyKind: site.questionnaireFamilyKind,
  additionalRequiredActions: site.additionalRequiredActions,
});

export const writeVisitTransformer = (v: VisitModel): VisitWriteModel => {
  return {
    startDatetime: datetimeTransformer(v.entryDateTime),
    endDatetime: datetimeTransformer(v.exitDateTime),
    markedAsEmployee: v.markedAsEmployee,
    purpose: combinePurpose(v),
    visitor: visitorTransformer(v.visitor, internalVisitorStatuses),
    host: { id: v.host.id },
    site: { id: v.site.id },
  };
};

export const writeVisitSeriesTransformer = (
  v: VisitModel,
): VisitSeriesWriteModel => ({
  recurrenceKind: v.repeatFrequency!,
  startDate: v.entryDateTime.format('YYYY-MM-DD'),
  endDate: v.repeatEndDate!.format('YYYY-MM-DD'),
  entryTime: v.entryDateTime.format('HH:mm:ss'),
  exitTime: v.exitDateTime.format('HH:mm:ss'),
  purpose: combinePurpose(v),
  markedAsEmployee: v.markedAsEmployee,
  visitor: visitorTransformer(v.visitor, internalVisitorStatuses),
  host: { id: v.host.id },
  site: { id: v.site.id },
});

export const answerWriteTransformer = ({
  questionId,
  answer,
  expects,
}: {
  questionId: string;
  answer: any;
  expects?: FormConfigQuestionExpectsModel;
}) => {
  if (expects?.type === 'SITE_ENTRIES') {
    return {
      valueSiteEntries:
        answer?.map(
          (a: {
            date: Dayjs;
            time: Dayjs;
            value: string;
            details?: string;
          }) => ({
            value: a.value,
            datetime: formatLocalDateTime(combineDateAndTime(a.date, a.time)),
            details: a.details,
          }),
        ) ?? null,
    };
  } else if (typeof answer == 'boolean') {
    return { valueBool: answer };
  } else if (dayjs.isDayjs(answer)) {
    return questionId.toLowerCase().endsWith('datetime')
      ? { valueDatetime: datetimeTransformer(answer) }
      : { valueDate: answer.format('YYYY-MM-DD') };
  } else if (typeof answer == 'string') {
    return { valueString: answer };
  } else if (Array.isArray(answer)) {
    return { valueOptions: answer };
  }

  console.error("couldn't convert answer", answer);
  return {};
};

export const writeAnswerMapTransformer = ({
  answers,
  config,
}: {
  answers: AnswerValueMap | undefined;
  config: FormConfigModel | undefined;
}): Record<string, FormQuestionAnswer> => {
  const questionMap = new Map(
    config?.pages.flatMap(p => p.questions).map(q => [q.questionId, q]),
  );

  return Object.fromEntries(
    Object.entries(answers ?? {}).map(([questionId, answer]) => [
      questionId,
      answerWriteTransformer({
        questionId,
        answer,
        expects: questionMap.get(questionId)?.expects,
      }),
    ]),
  );
};

export const formFinishRequestTransformer = (
  data: PostQuestionnaireVariables,
): FormFinishRequest => ({
  questions: data.answers
    ? writeAnswerMapTransformer({ answers: data.answers, config: data.config })
    : {},
  completionTime: data.completionTime,
  qrTimestamp: data.qrTimestamp?.format(),
  signScanType: data.signScanType,
});

export const responsesToAnswerValueMap = (
  responses: QuestionResponseMapModel,
): AnswerValueMap =>
  Object.fromEntries(
    Object.entries(responses ?? {}).map(([questionId, { value }]) => [
      questionId,
      value,
    ]),
  );
