import { isBoolean, isFinite, isNil, omit, pick, sortBy } from 'lodash';
import {
  OptionType,
  SPECIAL_CASE_OPTION_VALUES,
} from 'xo/hooks/component-hooks';

export const modelsToOptions = <TModel extends { id: string; name?: string }>(
  models?: TModel[],
  opts?: {
    values?: (m: TModel) => string;
    labels?: (m: TModel) => string;
    search?: (m: TModel) => Partial<TModel>;
  },
): OptionType<string>[] => {
  const options: OptionType<string>[] =
    models?.map(m => ({
      key: m.id,
      label: opts?.labels ? opts.labels(m) : m.name ?? m.id,
      value: opts?.values ? opts.values(m) : m.id,
      searchFields: opts?.search ? opts.search(m) : undefined,
    })) ?? [];

  return sortBy(options, m => m.label);
};

export const coalesceEmpty = <T extends any>(
  value?: T,
  coalesceTo: any = null,
) => (isBoolean(value) || isFinite(value) ? value : value || coalesceTo);

export const coalesceInt = (value?: string | number) => {
  const maybeNum = !isNil(value) ? parseInt(value as string) : null;
  return isFinite(maybeNum) ? maybeNum : null;
};

export const coalesceEmptyObj = <T extends {}>(
  values: T,
  coalesceTo: any = null,
) =>
  Object.fromEntries(
    Object.entries(values).map(([key, value]) => [
      key,
      coalesceEmpty(value, coalesceTo),
    ]),
  ) as T;

export const coalesceOtherUnknown = (value?: string) =>
  value && SPECIAL_CASE_OPTION_VALUES.includes(value) ? undefined : value;

export const pickFields = <T extends object>(
  obj: T,
  fields: (keyof T)[],
): Omit<T, '__typename'> => pick(obj, fields);

export const omitFields = <T extends object>(obj: T, fields: (keyof T)[]) =>
  omit(obj, fields);
