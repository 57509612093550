import { Tooltip } from 'antd';
import classNames from 'classnames';
import { IconButton } from 'xo/components/icon-button';
import { Box } from 'xo/core';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { Color } from 'xo/styles/restyle/theme';
import { SvgMinus } from 'xo/svg/svg-minus';
import { hexFromThemeColor } from 'xo/utils/theme-utils';
import { flexColCenterCenter } from '../app-constants';
import { SiteKindTag } from '../components/site-kind-tag';

type QuarantineSiteKind = ApiSiteKind | 'External';

interface SiteHeaderProps {
  name: string;
  kind: QuarantineSiteKind;
  simpleTooltips: boolean;
  color?: Color;
  kindPrefix?: string;
}

type ExternalSiteHeaderProps = Omit<SiteHeaderProps, 'kindPrefix' | 'color'> & {
  orgName: string;
};

export const QuarantineSiteHeader = ({
  name,
  kind,
  kindPrefix,
  simpleTooltips,
  color,
}: SiteHeaderProps) => (
  <div
    className={classNames(
      flexColCenterCenter,
      'whitespace-normal text-center ',
    )}
  >
    <div style={{ color: color ? hexFromThemeColor(color) : undefined }}>
      {simpleTooltips ? (
        <span title={name}>{name}</span>
      ) : (
        <Tooltip overlay={name} trigger={['click', 'hover']}>
          <span className="cursor-pointer">{name}</span>
        </Tooltip>
      )}
    </div>

    <SiteKindTag kind={kind} prefix={kindPrefix} className="mt-1" size="xs" />
  </div>
);

export const QuarantineExternalSiteHeader = ({
  name,
  orgName,
  ...props
}: ExternalSiteHeaderProps) => (
  <Box gap="2">
    <Box w="full" alignItems="flex-end">
      <IconButton icon={<SvgMinus />} variant="grey" size="md" />
    </Box>
    <QuarantineSiteHeader
      {...props}
      name={`${name} - ${orgName}`}
      kindPrefix="External"
      color="red.500"
    />
  </Box>
);
