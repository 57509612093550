import { useForm } from 'antd/es/form/Form';
import { PropsWithChildren } from 'react';
import { useIsLoggedIn } from 'xo/login/auth-status';
import { useOptionalCurrentUser } from 'xo/login/current-user-hooks';
import { useFormSubmit, useIsXoAdmin } from '../../hooks/shared-hooks';
import { usePostTermsAgreementAuthed } from '../../hooks/user-network-hooks';
import { ClickthroughAgreement } from '../components/clickthrough-agreement';
import { Container } from '../components/container';
import { Page } from '../components/page';
import { PageProvider } from '../components/page-provider';
import { useRedirectOnLogin } from '../people-office/visitor-log-hooks';

export const TermsPageAuthed = ({ children }: PropsWithChildren) => {
  const redirectOnLogin = useRedirectOnLogin();

  const [form] = useForm();

  const { mutate: termsAgreement } = usePostTermsAgreementAuthed();
  const { onSubmit: onTermsAgree, loading: termsLoading } = useFormSubmit({
    type: 'react-query',
    form,
    onSubmit: termsAgreement,
    onSuccess: redirectOnLogin,
  });

  const isXoAdmin = useIsXoAdmin();
  const isLoggedIn = useIsLoggedIn();
  const user = useOptionalCurrentUser();
  const renderTerms = isLoggedIn && !isXoAdmin && user && !user.agreedTerms;

  if (!renderTerms) {
    return children;
  }

  return (
    <PageProvider>
      <Page
        footer={{
          middle: {
            active: true,
            label: 'Continue',
            onClick: onTermsAgree,
            loading: termsLoading,
          },
        }}
      >
        <Container>
          <ClickthroughAgreement form={form} />
        </Container>
      </Page>
    </PageProvider>
  );
};
