import { PhoneSupport, ValidatePhoneResponse } from 'xo/rest-api';
import { countryNameByAbbr } from './constants';
import logger from './logger';
import { countryCodeOptions } from './utils/phone-utils';

export const EMAIL_REGEX = /^.+@.+\..+$/;
export const isValidEmail = (value: string) => EMAIL_REGEX.test(value);

export interface IsValidPhoneNumberParams {
  phone: string | undefined;
  requireMobile?: boolean;
  required?: boolean;
  // allows overriding the default error code messages
  errorCodeMessages?: PhoneValidationErrorCodeMessages;
  // if we need to specify the origin for the API call. necessary when consuming in the mobile app
  origin?: string;
}

export type PhoneValidationErrorCodeMessages = Record<
  Exclude<PhoneSupport, 'SUPPORTED'>,
  string
>;

export const defaultErrorCodeMessages: PhoneValidationErrorCodeMessages = {
  UNSUPPORTED_REGION: `Please provide a mobile number from a different country, or use email`,
  UNSUPPORTED_NUMBER_TYPE: `Please provide a valid mobile number`,
};

export const serverPhoneValidationError =
  'Sorry, a server error prevented phone validation';

// https://stackoverflow.com/questions/14894899/what-is-the-minimum-length-of-a-valid-international-phone-number/43993566#43993566
const MIN_INTL_PHONE_LENGTH = 7;

export const invalidPhoneMessage = ({
  countryName,
  requireMobile,
}: {
  countryName: string;
  requireMobile: boolean;
}) =>
  `Please provide a valid ${countryName} ${requireMobile ? 'mobile' : 'phone'} number`;

export interface IsValidPhoneNumber extends ValidatePhoneResponse {
  error?: string;
}

export const isValidPhoneNumber = async ({
  phone: propsPhone,
  required,
  requireMobile,
  origin,
  errorCodeMessages = defaultErrorCodeMessages,
}: IsValidPhoneNumberParams): Promise<IsValidPhoneNumber> => {
  const phone = propsPhone?.replace(/ /g, '') ?? '';

  // optional phone => empty phone is fine
  if (!phone && !required) {
    return { valid: true };
  }

  const phoneRegion = countryCodeOptions.find(({ label }) =>
    phone.startsWith(label),
  )?.value;

  if (!phoneRegion) {
    return { valid: false, error: defaultErrorCodeMessages.UNSUPPORTED_REGION };
  }

  const countryName = countryNameByAbbr[phoneRegion];
  const invalidMessage = invalidPhoneMessage({
    countryName,
    requireMobile: !!requireMobile,
  });

  // early out if the string isn't the right length
  if (phone.length < MIN_INTL_PHONE_LENGTH) {
    return { valid: false, error: invalidMessage };
  }

  let data: ValidatePhoneResponse | undefined = undefined;
  const serverErrorResponse = {
    valid: false,
    error: serverPhoneValidationError,
  };
  try {
    const response = await fetch(
      `${origin ?? ''}/api/v3-open/validation/phone?${new URLSearchParams({
        phone,
        country: phoneRegion,
      })}`,
    );

    data = await response.json();

    if (!response.ok) {
      return serverErrorResponse;
    }
  } catch (e) {
    logger.error(e);

    return serverErrorResponse;
  }

  if (!data || !data.valid) {
    return { valid: false, error: invalidMessage };
  }

  // if we require a mobile number, verify that the number supports a notification channel
  const phoneSupport = [data.supportsSms, data.supportsWhatsapp];
  if (requireMobile && !phoneSupport.some(s => s === 'SUPPORTED')) {
    const unsupported = phoneSupport.find(s => s !== 'SUPPORTED') as
      | Exclude<PhoneSupport, 'SUPPORTED'>
      | undefined;
    return {
      ...data,
      valid: false,
      // we shouldn't have a case where unsupported is undefined, but just in case return number type error
      error: errorCodeMessages[unsupported ?? 'UNSUPPORTED_NUMBER_TYPE'],
    };
  }

  return data;
};
