// This file is also imported in the E2E tests
import { isEqual } from 'lodash';

export const isShowOnEqual = ({
  value,
  showOn,
}: {
  value?: any;
  showOn: any[];
}) => {
  const valueArr = Array.isArray(value) ? value : [value];
  return showOn.some(s => valueArr.some(v => isEqual(s, v)));
};

export const MOST_RECENT_GROUP_CONFIG = [
  {
    parentQuestionId: 'last-entry-no-most-recent',
    orgQuestionId: 'last-entry-no-most-recent-org',
    dateQuestionId: 'last-entry-no-most-recent-org-matrix-date',
    siteQuestionId: 'last-entry-no-most-recent-org-matrix-site',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry-no-most-recent',
    orgQuestionId: 'last-entry-no-most-recent-org',
    dateQuestionId: 'last-entry-no-most-recent-outside-org-not-org-date',
    siteQuestionId: 'last-entry-no-most-recent-outside-org-not-org-type',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry-most-recent-other',
    orgQuestionId: 'last-entry-most-recent-other-org',
    dateQuestionId: 'last-entry-most-recent-other-org-matrix-date',
    siteQuestionId: 'last-entry-most-recent-other-org-matrix-site',
  },
  {
    parentQuestionId: 'last-entry-most-recent-other',
    orgQuestionId: 'last-entry-most-recent-other-org',
    dateQuestionId: 'last-entry-most-recent-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry-most-recent-other-outside-org-not-org-type',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent',
    orgQuestionId: 'last-entry2-no-most-recent-org',
    dateQuestionId: 'last-entry2-no-most-recent-org-matrix-date',
    siteQuestionId: 'last-entry2-no-most-recent-org-matrix-site',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent',
    orgQuestionId: 'last-entry2-no-most-recent-org',
    dateQuestionId: 'last-entry2-no-most-recent-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-no-most-recent-outside-org-not-org-type',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other',
    orgQuestionId: 'last-entry2-most-recent-other-org',
    dateQuestionId: 'last-entry2-most-recent-other-org-matrix-date',
    siteQuestionId: 'last-entry2-most-recent-other-org-matrix-site',
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other',
    orgQuestionId: 'last-entry2-most-recent-other-org',
    dateQuestionId: 'last-entry2-most-recent-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-most-recent-other-outside-org-not-org-type',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent2',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date',
    siteQuestionId: 'last-entry2-other-org-matrix-site',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent2',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other2',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date',
    siteQuestionId: 'last-entry2-other-org-matrix-site',
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other2',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent3',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date',
    siteQuestionId: 'last-entry2-other-org-matrix-site',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent3',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other4',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date',
    siteQuestionId: 'last-entry2-other-org-matrix-site',
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other4',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent4',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date',
    siteQuestionId: 'last-entry2-other-org-matrix-site',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent4',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other5',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date',
    siteQuestionId: 'last-entry2-other-org-matrix-site',
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other5',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent4',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date2',
    siteQuestionId: 'last-entry2-other-org-matrix-site2',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other5',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date2',
    siteQuestionId: 'last-entry2-other-org-matrix-site2',
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent5',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date2',
    siteQuestionId: 'last-entry2-other-org-matrix-site2',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent5',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other6',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-other6',
    orgQuestionId: 'last-entry2-other-org',
    dateQuestionId: 'last-entry2-other-org-matrix-date2',
    siteQuestionId: 'last-entry2-other-org-matrix-site2',
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent-feedmill',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-org-feedmill-matrix-date',
    siteQuestionId: 'last-entry2-other-org-feedmill-matrix-site',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent-feedmill',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-feedmill-other2',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-org-feedmill-matrix-date',
    siteQuestionId: 'last-entry2-other-org-feedmill-matrix-site',
  },
  {
    parentQuestionId: 'last-entry2-most-recent-feedmill-other2',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent-feedmill2',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-org-feedmill-matrix-date',
    siteQuestionId: 'last-entry2-other-org-feedmill-matrix-site',
    internal: true,
  },
  {
    parentQuestionId: 'last-entry2-no-most-recent-feedmill2',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-feedmill-other3',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-org-feedmill-matrix-date',
    siteQuestionId: 'last-entry2-other-org-feedmill-matrix-site',
  },
  {
    parentQuestionId: 'last-entry2-most-recent-feedmill-other3',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    parentQuestionId: 'last-entry2-most-recent-feedmill-other4',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-org-feedmill-matrix-date',
    siteQuestionId: 'last-entry2-other-org-feedmill-matrix-site',
  },
  {
    parentQuestionId: 'last-entry2-most-recent-feedmill-other4',
    orgQuestionId: 'last-entry2-other-org-feedmill',
    dateQuestionId: 'last-entry2-other-outside-org-not-org-date',
    siteQuestionId: 'last-entry2-other-outside-org-not-org-type2',
    siteIndicatesCompliance: true,
  },
  {
    dateQuestionId: 'chicken-contact-last-contact-date',
    siteQuestionId: 'chicken-contact-last-contact-site',
    // reuse that question for these, since there's no corresponding question
    parentQuestionId: 'chicken-contact-last-contact-site',
    orgQuestionId: 'chicken-contact-last-contact-site',
  },
  {
    dateQuestionId: 'chicken-delivery-contact-internal-date-2',
    siteQuestionId: 'chicken-delivery-contact-internal-site',
    orgQuestionId: 'chicken-delivery-contact-which-org',
    // reuse that question for this, since there's no corresponding question
    parentQuestionId: 'chicken-delivery-contact-which-org',
  },
  {
    dateQuestionId: 'pork-delivery-contact-internal-date',
    siteQuestionId: 'pork-delivery-contact-internal-site',
    orgQuestionId: 'pork-delivery-contact-which-org',
    // reuse that question for this, since there's no corresponding question
    parentQuestionId: 'pork-delivery-contact-which-org',
  },
  {
    dateQuestionId: 'feed-mill-delivery-contact-internal-date',
    siteQuestionId: 'feed-mill-delivery-contact-internal-site',
    orgQuestionId: 'feed-mill-delivery-contact-which-org',
    // reuse that question for this, since there's no corresponding question
    parentQuestionId: 'feed-mill-delivery-contact-which-org',
  },
];

export const scrollErrorIntoViewSettings = {
  duration: 400,
  axis: 'y' as const,
  offset: 20,
  cancelable: false,
};

export const declarationCheckboxText =
  'I have read and understand the information provided and acknowledge the requirements. I hereby certify that my answers are true and correct to the best of my knowledge.';
