import classNames from 'classnames';
import React from 'react';
import { siteKindNameMap } from 'xo/constants';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { PanelTag } from './panel-tag';

export type SiteKindTagSize = 'xs' | 'sm' | 'lg';

export interface SiteKindTagProps {
  className?: string;
  kind: ApiSiteKind | string;
  size?: SiteKindTagSize;
  prefix?: string;
}

export const SiteKindTag: React.FC<SiteKindTagProps> = ({
  kind,
  className,
  size = 'lg',
  prefix,
}) => {
  const text = siteKindNameMap[kind as ApiSiteKind] ?? 'Other';

  const textWithPrefix = prefix && `${prefix} - ${text}`;

  return (
    <PanelTag
      className={classNames(
        className,
        'max-w-lg whitespace-normal bg-grey-200',
        { 'text-base': size === 'lg' },
        { 'text-sm': size === 'sm' },
        { 'text-xs': size === 'xs' },
      )}
    >
      {textWithPrefix ?? text}
    </PanelTag>
  );
};
